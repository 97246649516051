<template>
  <div class="wrapper">
    <div class="document-viewer" v-if="opened">

        <div class="panel" v-if="loading">
            <div class="loader"></div>
        </div>
        
        <div class="panel" v-else>
            <pdf-document-view-frame v-if="document.documentType === 'pdf'" :document="document" :options="options" :projectJwtData="projectJwtData"/>
            <rsx-document-view-frame v-else-if="document.documentType === 'rsx' || document.documentType === 'zsx'" :document="document" :options="options" :projectJwtData="projectJwtData"/>
            <div v-else>
                <p>Dit documenttype kan niet worden geopend.</p>
            </div>
            <div class="close">
                <q-button size="xsmall" variation="danger" @click="close">Sluiten</q-button>
            </div>
            
        </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import PDFDocumentViewFrame from './PDFDocumentViewFrame.vue';
import RSXDocumentViewFrame from './RSXDocumentViewFrame.vue';


export default {
    name: 'document-viewer',
    components: {
        'pdf-document-view-frame': PDFDocumentViewFrame,
        'rsx-document-view-frame': RSXDocumentViewFrame
    },
    data() {
        return {
            opened: false,
            loading: true,
            document: null,
            projectJwtData: undefined,
            options: {}
        }
    },
    methods: {
        close() {
            this.opened = false;
            this.document = null;
            this.projectJwtData = null;
            this.options = {};
        },
        async open(documentId, options = {}) {
            return new Promise(async (resolve, reject) => {
                this.opened = true;
                this.loading = true;
                this.options = options;

                try {
                    const projectJwtString = localStorage.getItem('projectJwt')
                    const projectJwtData = (JSON.parse(projectJwtString) || {});

                    this.projectJwtData = projectJwtData;

                    const documentResult = await this.$apollo.query({
                        query: gql`
                            query ($documentId: String!, $projectId: String!, $projectJwt: String!, $fields: [String]!) {
                                documents_getProjectDocuments(documentId: $documentId, projectJwt: $projectJwt, projectId: $projectId, fields: $fields)
                            }
                        `,
                        variables: {
                            documentId,
                            projectId: projectJwtData.id,
                            projectJwt: projectJwtData.jwt,
                            fields: ['name', 'uploadTimestamp', 'uploadedUser', 'documentId', 'documentType']
                        },
                    })

                    if (documentResult.data.documents_getProjectDocuments.length === 0) {
                        this.close();
                        reject('Document not found');
                        return;
                    }

                    this.document = documentResult.data.documents_getProjectDocuments[0].fields;

                    this.loading = false;
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        }
    },
}
</script>

<style scoped lang="scss">

.wrapper {

}

.document-viewer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.241);
    display: flex;
    justify-content: center;
    z-index: 1000;
    overflow-y: hidden;
    padding: 40px 0 80px 0;
}

.panel {
    position: relative;
    margin: 0 0 0 0;
    width: 80%;
    height: 100%;
    padding: 40px 20px 20px 20px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.5);
}

.loader {
    
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
}

</style>